import { MeiliSearch } from 'meilisearch'

interface SearchOptions {
    page?: number
    hitsPerPage?: number
    filter?: string
    sort?: string
    query?: string
}

export function useAsyncMeiliSearch(index: string, initialOptions: SearchOptions = {}) {
    const meili = inject<MeiliSearch>('meilisearch-client')
    if (!meili) {
        throw new Error('MeiliSearch client not found')
    }

    const hits = ref<any[]>([])
    const currentPage = ref(initialOptions.page || 1)
    const searchQuery = ref(initialOptions.query || '')
    const hitsPerPage = ref(initialOptions.hitsPerPage || 20)
    const filter = ref(initialOptions.filter || '')
    const sort = ref(initialOptions.sort)

    const total = ref(0)
    const loading = ref(false)
    const error = ref<string | null>(null)

    const setHitsPerPage = (value: number) => {
        hitsPerPage.value = value
    }

    const setPage = (value: number) => {
        currentPage.value = value
    }

    const setFilter = (value: string) => {
        filter.value = value
    }

    const setSort = (value: string) => {
        sort.value = value
    }

    const setQuery = (value: string) => {
        searchQuery.value = value
    }

    watchEffect(async () => {
        loading.value = true
        error.value = null
        try {
            const result = await meili.index(index).search(searchQuery.value, {
                page: currentPage.value,
                hitsPerPage: hitsPerPage.value,
                filter: filter.value,
                sort: sort.value ? [sort.value] : undefined,
            })
            hits.value = result.hits
            total.value = result.totalHits
        } catch (e) {
            error.value = e instanceof Error ? e.message : 'An error occurred during search'
            hits.value = []
            total.value = 0
        } finally {
            loading.value = false
        }
    })

    return {
        hits: computed(() => hits.value),
        total: computed(() => total.value),
        currentPage: computed(() => currentPage.value),
        hitsPerPage: computed(() => hitsPerPage.value),
        loading: computed(() => loading.value),
        error: computed(() => error.value),
        setHitsPerPage,
        setPage,
        setFilter,
        setSort,
        setQuery
    }
} 